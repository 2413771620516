:root {
  --primary-color: #FFF; /* linear-gradient(#0071E3, rgb(255, 255, 255)) */
  --secondary-color: #e0f0e4;
  --tertiary-color: #649470;
  --light-grey: #B2B2B2;
  --background-color: #FFF;
  --apple-blue: #0071E3;
  --darker-color: #3b3b3b;
  --black-color: black; 
  --text-color: #2d2d2d;
  --error-color: #dc3545;
  --success-color: #28a745;

  --main-font: 'Poppins', sans-serif;
}

/* You only need this import here and it should be on every page */
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

/* Font sizes */

h1 {
  font-size: 3vw; /* Adjusts with viewport width */
}

h2 {
  font-size: 2.5vw;
}

h3 {
  font-size: 2vw;
}

p, .description {
  font-size: 1.5vw;
}

/* If any class is used on multiple pages it should be added here */

body {
  font-family: var(--main-font);
  margin: 0;
  padding: 0;
  background-color: var(--background-color);
}


/* Heroes */


/* Base Hero Style - Common styles for all hero sections */
.hero {
  font-family: var(--main-font);
  align-content: center;
  padding: 1rem;
  padding-top: 0;
  margin-bottom: 0.5rem;
}

/* Top Hero - Additional padding for heroes at the top of the page (usually under navigation) */
.top-hero{
  padding-top: calc(7vw + 5vh);
}

/* Hero-1 - Full-width hero with stacked content
   Used for main landing sections and introductory content.
   Text and image are stacked vertically in a column layout. */

.hero-1 {
  background-image: var(--background-color);
  color: var(--text-color);
  text-align: center;
  display: flex;
  flex-direction: column;
}

.hero-1 .hero-text {
  margin: 0;
  padding: 0;
}

.hero-1 .hero-image {
  margin: 0;
  padding: 0;
  border-radius: 10px;
}

/* Hero-2 - Dark background hero with 50/50 split
   Used for high-contrast sections with equal text and image areas.
   Dark background with light text for emphasis. */

.hero-2 {
  background-color: var(--black-color);
  color: var(--background-color);
  text-align: center;
}

.hero-2 .hero-text {
  width: 50%;
  margin: 0;
  padding: 0;
  font-family: "Poppins", sans-serif;
  font-weight: 350;
  text-align: center;
  margin: auto;
  max-width: 50%;
}

.hero-2 .hero-image {
  width: 50%;
  margin: 0;
  padding: 0;
  background-color: var(--secondary-color);
  border-radius: 10px;
}

/* Hero-3 - Light background hero with 50/50 split
   Used for regular content sections with equal text and image areas.
   Similar to Hero-2 but with light background for less emphasis. */

.hero-3 {
  background-color: var(--background-color);
  text-align: center;
  align-content: center;
}

.hero-3 .hero-text {
  width: 50%;
  margin: 0;
  padding: 0;
}

.hero-3 .hero-image {
  width: 50%;
  margin: 0;
  padding: 0;
  border-radius: 10px;
}


/* Lists */

.ol {
  font-size: 1.2rem;
}

/* Buttons */

.button {
  align-self: center;
  color: var(--background-color);
  width: fit-content;
  padding: 8px 16px;
  background-color: var(--tertiary-color);
  font-family: var(--main-font);
  border: none;
  border-radius: 1.5rem;
  margin: 14px;
  cursor: pointer;
  transform: all 0.6s ease;
  transition: transform 0.3s ease, background-color 0.3s, color 0.3s;
}
.button:hover {
  background-color: transparent;
  color: var(--text-color);
}

.standout-button {
  font-size: 2.5vw;
  font-weight: bold;
  z-index: 5;
  width: 20rem;
  padding: 15px;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  margin-bottom: 20px;
  box-sizing: border-box;
  transition: background-color 0.3s, color 0.3s;
  background-color: var(--tertiary-color);
  cursor: pointer;
}

.button-container {
  padding-bottom: 2rem;
  max-height: 4rem;
}

.button-group {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 20px;
}

.button-group button {
  flex: 1;
}

.link-button {
  background: none;
  border: none;
  color: var(--apple-blue);
  text-decoration: underline;
  cursor: pointer;
  padding: 0;
  font: inherit;
}

.secondary-button {
  background-color: var(--secondary-color);
  margin-left: 10px;
}

/* Grids */

.content-grid-1 {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  margin-inline: 5rem;
}
.content-grid-2 {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  margin-inline: 5rem;
}
.content-grid-3 {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  margin-inline: 5rem;
  gap: 5%;
}

/* Difference between container, textbox, card, section title etc. */

.container {
  margin: 1rem auto;
  padding: 2rem;
  flex-basis: calc(80% - 15px);
  background-color: var(--background-color);
  color: var(--text-color);
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0,0,0,0.1);
  z-index: 1;
}

.textbox {
  text-align: center;
  width: 80%;
  margin: 1rem auto;
  padding-bottom: 1rem;
  border-radius: 1rem;
  box-shadow: 0 0 10px rgba(0,0,0,0.1);
  z-index: 1;
}

.card {
  background-color: var(--background-color);
  color: var(--text-color);
  padding: 1rem;
  border-radius: 3rem;
  box-shadow: 0 2px 0px rgb(0, 0, 0, 0.3);
  transition: transform 0.3s ease;
}

.card:hover {
  transform: translateY(-5px);
}

.image-content {
  max-height: 20rem;  
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 5px;
  position: relative;
  padding-top: 1rem;
}

.section-title {
  font-size: 2rem;
  color: var(--text-color);
  background-color: var(--background-color);
  text-align: center;
  height: 100%;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0,0,0,0.1);
  margin: 5px;
}

.section-content {
  font-size: 1rem;
  color: var(--text-color);
  background-color: var(--background-color);
  text-align: justify;
  height: 100%;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0,0,0,0.1);
  padding-left: 5%;
  padding-right: 5%;
  margin: 5px;
}



a {
  color: var(--light-grey);
  text-decoration: none;
  transition: color 0.3s ease;
}

a:hover {
  color: var(--primary-color);
}

/* Form Components */
.form-group {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  margin-bottom: 20px;
}

.form-group input {
  width: 100%;
  max-width: 300px;
  padding: 8px;
  border: 1px solid var(--secondary-color);
  border-radius: 4px;
}

.interest-form {
  width: 90%;
  max-width: 600px;
  margin: 2rem auto;
  padding: 2rem;
  background-color: var(--background-color);
  border-radius: 10px;
}

.interest-form input,
.interest-form select,
.interest-form textarea {
  width: 100%;
  margin-bottom: 1rem;
  border: 1px solid var(--secondary-color);
  border-radius: 4px;
  font-family: var(--main-font);
  background-color: var(--background-color);
  color: var(--text-color);
}

.interest-form textarea {
  min-height: 120px;
  resize: vertical;
}

.interest-form button {
  margin-top: 1rem;
}

.form-title {
  text-align: center;
  margin-bottom: 1.5rem;
}

/* Message Components */
.error-message {
  color: var(--error-color);
  padding: 10px;
  margin-bottom: 15px;
  border-radius: 4px;
  background-color: var(--background-color);
}

.success-message {
  color: var(--success-color);
  padding: 10px;
  margin-bottom: 15px;
  border-radius: 4px;
  background-color: var(--background-color);

}


/* Swiper elements */

.slide-content {
  align-items: center;
  padding-bottom: 3rem;
}

.swiper-button-next, .swiper-button-prev {
  bottom: 10px; /* Position at the bottom */
  top: auto; /* Override default top positioning */
  transform: translateY(50%); /* Center vertically */
}

.swiper-button-next {
  display: none;
  right: 40%; /* Adjust as needed */
}

.swiper-button-prev {
  display: none;
  left: 40%; /* Adjust as needed */
}

/* Width differences */ 

@media (max-width: 1150px) {


  h1 {
    font-size: 2rem; /* Adjusts with viewport width */
  }

  h2 {
    font-size: 1.5rem;
  }

  h3 {
    font-size: 1.2rem;
  }

  p, .description {
    font-size: 1rem;
  }

  .content-grid-1 {
    grid-template-columns: 1fr;
  }
  .content-grid-2 {
    grid-template-columns: 1fr;
  }

  .hero-text {
    width: 100%; 
    margin: 0;
    padding: 0;
  }
  
  .hero-image {
    width: 100%;
    margin: 0;
    padding: 0;
  }

  .interest-form {
    width: 75%;
    padding: 0;
  }
  
}

