.header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background-color: var(--background-color);
  color: var(--background-color);
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.3);
  z-index: 10;
}

.header-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 4vh;
  padding: 1rem;
  /* box-shadow: 0 0.5rem 1rem rgba(0,0,0,0.1); */
  z-index: 9; /* Adjust z-index to be behind the header */
}

.logo {
  display: flex;
  height: 2.25rem;
  padding-left: 1%;
}

.nav-links {
  display: flex;
  align-items: center;
  gap: 3rem;
  padding: 1rem;
  color: var(--text-color);
  z-index: 10; /* Adjust z-index to be behind the header */
}

.nav-links li {
  list-style-type: none;
}

.nav-links a {
  border-radius: 0.5rem;
  text-decoration: none;
}

.nav-links a:hover {
  color: var(--text-color);
}

.auth-buttons-container{
  display: flex;
  max-height: 4vh;
  gap: 1.5vw;
  align-items: center;
  justify-content: center;
}

.auth-button {
  font-weight: bold;
  text-align: center;
  transition: background-color 0.3s ease;
  min-width: 5vw;
  padding-inline: 1rem;
  padding: 1rem;
  height: 1.8vh;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
}

.login-button {
  background-color: var(--secondary-color);
  color: var(--darker-color); 
}

.login-button:hover {
  background-color: var(--primary-color);
  color: var(--secondary-color);
}

.startresearch-button {
  background-color: var(--tertiary-color);
  color: var(--background-color); 
}

.startresearch-button:hover {
  background-color: var(--primary-color);
  color: var(--darker-color);
}

.myaccount-button {
  background-color: var(--secondary-color);
  color: var(--darker-color);
}


.signup-button {
  background-color: var(--tertiary-color);
  color: var(--background-color);
}

.menu-toggle {
  display: none;
}

.menu-icon {
  display: block;
  width: 25px;
  height: 2px;
  background-color: var(--text-color); /* Add this line */
  position: relative;
  transition: background-color 0.3s ease;
}

.menu-icon::before,
.menu-icon::after {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: var(--text-color); /* Add this line */
  transition: all 0.3s ease;
}

.menu-icon::before {
  top: -8px;
}

.menu-icon::after {
  bottom: -8px;
}

.menu-toggle.open .menu-icon {
  background-color: transparent;
}

.menu-toggle.open .menu-icon::before {
  transform: rotate(45deg);
  top: 0;
}

.menu-toggle.open .menu-icon::after {
  transform: rotate(-45deg);
  bottom: 0;
}

.account-menu {
  display: fixed;
}

.account-trigger {
  cursor: pointer;
  font-weight: bold;
  padding-bottom: 30px;
  margin-bottom: -30px;
}

.account-dropdown {
  display: none;
  position: absolute;
  top: 100%;
  right: 0;
  background-color: var(--background-color);
  border: 1px solid var(--secondary-color);
  border-radius: 4px;
  padding: 1rem;
  min-width: 200px;
  box-shadow: 0 2px 5px rgba(0,0,0,0.2);
  z-index: 100;
  
}


.account-trigger:hover .account-dropdown {
  display: block;
  cursor: default;
  flex-direction: column;
  
}

.dropdown-container {
  display: flex;
  max-height: 6rem;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  background-color: var(--background-color);
  color: var(--text-color);
  border-radius: 10px;
}

.account-info {
  border-bottom: 1px solid var(--secondary-color);
  padding-bottom: 0.5rem;
  margin-bottom: 0.5rem;
  text-align: center;
}

.account-info p {
  margin: 0.25rem 0;
  color: var(--text-color);
  text-decoration: none;
  font-weight: normal;
}


@media (max-width: 1100px) {
  .header-content {
    flex-direction: column;
    align-items: flex-start;
    height: 2rem;
    padding: 1rem;
    z-index: 15;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
  }


  .menu-toggle {
    color: var(--text-color);
    background-color: transparent;
    border: none;
    cursor: pointer;
    display: flex;
    position: fixed;
    padding: 1rem;
    right: 1rem;
    transition: right 0.5s ease;
  }


  .nav-links {
    flex-direction: column;
    position: fixed;
    top: 120%;
    right: 0%;
    background-color: var(--background-color);
    width: 30%;
    height: 100vh;
    transition: top 0.5s ease;
    padding: 0.5rem;
    padding-top: 1rem;
    gap: 1.5rem;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
  }

  .nav-links.open {
    top: 3rem;
    z-index: -1;
  }

  .nav-links li {
    width: 100%;
    text-align: center;
  }

  .auth-buttons-container {
    margin-top: 1rem;
    flex-direction: column;
    width: 100%;
  }

  .auth-button {
    width: 25vw;
    padding: 0.5rem;
    align-items: center;
    font-size: clamp(0.8rem, 2vw, 1.2rem); /* Set font size based on viewport width */
  }

  .startresearch-button {
    order: -1;
    height: 1rem;
    max-width: 15rem;
  }
  
  .login-button {
    position: static;
    right: auto;
    height: 1rem;
    max-width: 15rem;
  }
  .dropdown-container {
    gap: 0.5rem;
  }

  .account-menu {
    margin-top: 5rem;
    width: 100%;
  }

  .account-trigger {
    width: 100%;
    padding: 0rem;
    margin: 0px;
  }

  .account-dropdown {
    position: static;
    display: block;
    flex-direction: column;
    width: 100%;
    margin-top: rem;
    min-width: 0;
    padding: 0;
    border-radius: 0;
    border: none;
    box-shadow: none;
    gap: 1rem;
  }
}