.about-page {
  background-color: var(--background-color); /* Slightly lighter shade of navy blue */
  box-sizing: border-box;
}

.intro {
  padding-top: 2rem;
  margin: 0 10%;
}

.team-section {
  text-align: center;
  margin: 2rem 0;
}

.team-images {
  width: 100%;
  overflow: hidden;
}


.team-images img {
  width: 13rem; /* Adjust size as needed */
  height: 15rem; /* Exclude the bottom 1 rem */
  margin-right: 20px; /* Space between images */
  border-radius: 10px; /* Optional: rounded corners */
}

@keyframes scroll {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-124.8rem); /* Move left */
  }
}
