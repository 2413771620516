.home-page {
  background-color: var(--background-color);
  
  font-family: "Poppins", sans-serif;
}

.home-page .top-hero {
  background-image: url('/public/backgroundhero.jpg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 30vw;
  
}

.home-page .hero-3 h2,
.home-page .hero-1 h2 {
  margin-bottom: 0;

}

.home-page .hero-grid-item {
  text-align: left;
  padding-left: 5vw;
}

.home-page .hero-grid-item h1 {
  font-weight: 500;
  font-size: 4vw;
  margin: 0;
  line-height: 1.3;
}

.home-page .hero-grid-item h3 {
  font-size: 2vw;
  font-weight: 300;
  margin: 0;
  line-height: 1.7;
}

.home-page .hero-grid-item h1:last-of-type {
  margin-bottom: 1.2rem;
}

.home-page .hero-grid-item button {
  align-self: flex-start;
  padding: 0;
}

.home-page .hero-grid-item .button-container {
  justify-content: flex-start;
  padding-left: 0;
  padding-top: 2.5vw;
  gap: 4vw;
}

.home-page .hero-grid-item .button,
.home-page .hero-grid-item .standout-button {
  margin: 0;
  border-radius: 10px;
  text-align: center;
  display: inline-block;
  font-weight: 400;
  width: auto;
  padding: 0.8vw 4vw;
  font-size: 1.4vw;
  letter-spacing: 1px;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.3);
}


.home-page .hero-grid-item .secondary-button {
  border: 2px solid var(--secondary-color);
  color: var(--darker-color);
}

.home-page .hero-grid-item .secondary-button:hover {
  border: 2px solid var(--secondary-color);

}

.home-page .hero-grid-item .standout-button {
  padding: 0.8vw 7vw;
  border: 2px solid var(--tertiary-color);
  
}

.home-page .hero-grid-item .standout-button:hover {
  border: 2px solid var(--tertiary-color);
}


.card-image {
  height: 100px;
  border-radius: 50%;
  background: #FFF;
  padding: 3px;
}

.img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  border-radius: 50%;
  /* border: 4px solid #4070F4; */
}

.promo-image {
  padding: 0;
  margin: 0;
  width: 90%;
  position: relative;
  box-shadow: var(--secondary-color);
}


/* Add this to your CSS file */




.animated-hero {
  height: 100vh;
  background-color: var(--background-color);
  overflow: hidden;
}

.letter {
  position: absolute;
  font-size: 20rem;
  font-weight: bold;
  color: var(--text-color);
  opacity: 0.1;
  user-select: none;
}

.letter-a {
  left: 10%;
  top: 50%;
  transform: translateY(-50%);
}

.letter-b {
  right: 10%;
  top: 50%;
  transform: translateY(-50%);
}

.crowd-container {
  width: 100%;
  height: 100%;
  pointer-events: none;
}
.stick-figure {
  top: 5rem;
  position: absolute;
  font-size: 2rem;
  transition: left 0.1s ease-out;
  user-select: none;
  color: black;
  pointer-events: none;

}

.network-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  opacity: 0.5;
}

.network-node {
  position: absolute;
  width: 12px;
  height: 12px;
  background-color: var(--tertiary-color);
  border-radius: 100%;
  transition: all 1s ease-in-out;
}

.network-connection {
  position: absolute;
  height: 1px;
  background-color: var(--tertiary-color);
  transform-origin: 0 0;
  opacity: 0.3;
  transition: all 1s ease-in-out;
}

@media (max-width: 768px) {
  .letter {
    font-size: 10rem;
  }
  
  .stick-figure {
    font-size: 1.5rem;
  }

  .home-page .top-hero {
    background-image: url('/public/herobackgroundmobile.jpg');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    height: 60vw;
    padding-top: calc(12vw + 5vh);
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.3);
  }


  .home-page .hero-grid-item .secondary-button {
    display: none;
  }

  .home-page .hero-grid-item h1 {
    font-weight: 500;
    font-size: 6.5vw;
    margin: 0;
    line-height: 1.3;
  }

  .home-page .hero-grid-item h1:last-of-type {
    margin-bottom: 2vw;
  }
  
  .home-page .hero-grid-item h3 {
    font-size: 3.5vw;
    font-weight: 300;
    margin: 0;
    line-height: 1.7;
  }

  .home-page .hero-grid-item .standout-button {
    margin: 0;
    border-radius: 10px;
    text-align: center;
    display: inline-block;
    font-weight: 500;
    width: auto;
    padding: 1.2vw 5vw;
    font-size: 2.8vw;
    letter-spacing: 1px;
    
  }

  .home-page .hero-grid-item .button-container {
    justify-content: flex-start;
    padding-left: 0;
    padding-top: 3.5vw;
    
  }

}


