.blog-page {
  background-color: var(--background-color); /* Slightly lighter shade of navy blue */
  color: var(--text-color);
}

.blog-view {
  display: fixed;
  grid-template-columns: 1;
  gap: 1rem;
}

.blog-box {
  background-color: var(--background-color);
  color: var(--text-color);
  text-align: center;
  max-width: 80%;
  margin: 1rem auto;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0,0,0,0.1);
  cursor: pointer;
  transition: transform 0.2s ease-in-out;
  padding: 1rem;
}

.blog-box:hover {
  transform: translateY(-5px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.blog-content {
  max-width: 800px;
  margin: 0 auto;
  padding: 2rem;
}