.pricing-page {
  text-align: center;
}

.pricing-page .hero {
  padding-top: calc(3vw + 5vh);
}

.pricing-container {
  display: flex;
  justify-content: center;
  gap: 2rem;
}


.pricing-card {
  border: 1px solid #ccc;
  background-color: var(--background-color);
  color: var(--text-color);
  border-radius: 8px;
  padding: 1.5rem;
  width: 300px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.pricing-card:hover {
  border-color: var(--tertiary-color);
  background-color: var( --primary-color);
}

.pricing-card.selected {
  border-color: var(--tertiary-color);
  background-color: var( --primary-color);
  border-width: 2px;
  pointer-events: auto; /* Enable interaction for the selected card */
}

.price {
  font-size: 2rem;
  margin: 1rem 0;
}

.pricing-btn {
  background-color: var(--tertiary-color);
  color: white;
  border: none;
  padding: 0.5rem 1rem;
  border-radius: 5px;
  cursor: pointer;
}

.pricing-page .button {
  background-color: var(--tertiary-color);
}

.pricing-btn:hover {
  background-color: var(--tertiary-color);
}

.pricing-faq {
  margin-top: 2rem;
}

.faq-container {
  margin: 2rem auto;
}

.faq-item {
  margin-bottom: 1rem;
  border-radius: 8px;
  overflow: hidden;
}

.faq-question {
  width: 100%;
  text-align: left;
  padding: 1rem;
  background-color: var(--background-color);
  border: 1px solid var(--border-color);
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 1rem;
  font-weight: 500;
  transition: all 0.3s ease;
}

.faq-question:hover {
  background-color: var(--primary-color);
}

.faq-question.active {
  border-bottom: none;
}

.faq-icon {
  font-size: 1.5rem;
  font-weight: 300;
  margin-left: 1rem;
}

.faq-answer {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.3s ease-out;
  border: 1px solid var(--border-color);
  border-top: none;
}

.faq-answer.open {
  max-height: 500px; /* Adjust based on content */
  padding: 1rem;
}

.faq-answer p {
  margin: 0;
  line-height: 1.5;
}

.pricing-card ul {
  text-align: left; /* Align text to the left */
}

@media (max-width: 900px) {
  .pricing-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .pricing-card {
    flex-direction: column;
    width: 20rem;
  }

}

.credit-info {
  margin: 15px 0;
  padding: 10px;
  background-color: rgba(var(--tertiary-color), 0.1);
  border-radius: 5px;
}

.credit-info p {
  font-weight: bold;
  margin-bottom: 8px;
}

.credit-info li {
  font-size: 0.9em;
  margin: 5px 0;
  list-style: none;
}

.pricing-card ul li {
  margin: 10px 0;
}