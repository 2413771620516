.personas-page {
  background-color: var(--background-color);
  padding: 4rem 2rem;
}

.personas-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2rem;
  max-width: 1400px;
  margin: 3rem auto;
}

.persona-card {
  background: var(--background-color);
  cursor: pointer;
  border-radius: 16px;
  padding: 2rem;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  border: 1px solid rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  gap: 1rem;
  position: relative;
  overflow: hidden;
  z-index: 1;
}

.persona-card::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 4px;
  background: linear-gradient(90deg, var(--primary-color), var(--secondary-color));
}

.persona-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 12px 24px rgba(0, 0, 0, 0.1);
}

.persona-card h3 {
  color: var(--text-color);
  margin: 0.5rem 0 0;
  font-size: 1.4rem;
  text-align: center;
}

.persona-card h4 {
  color: var(--darker-color);
  margin: 0 0 1rem 0;
  font-size: 1.1rem;
  text-align: center;
  font-weight: normal;
  line-height: 1.2;
}

.persona-card p {
  color: var(--text-color);
  line-height: 1.6;
  margin: 0;
  font-size: 1rem;
}

.persona-card ul {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.persona-card li {
  color: var(--text-color);
  padding-left: 1.5rem;
  position: relative;
  line-height: 1.4;
}

.persona-card li::before {
  content: '→';
  position: absolute;
  left: 0;
  color: var(--primary-color);
}

.intro-text,
.outro-text {
  max-width: 800px;
  margin: 0 auto 3rem;
  text-align: center;
  line-height: 1.6;
  color: var(--text-color);
}

/* Section header styling */
.personas-page h2 {
  text-align: center;
  font-size: 2.5rem;
  color: var(--darker-color);
  margin-bottom: 2rem;
  position: relative;
  padding-bottom: 1rem;
}

.personas-page h2::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 60px;
  height: 3px;
  background: var(--primary-color);
  border-radius: 2px;
}
.expanded-card .persona-image {
  width: 200px;
  height: 200px;
  object-fit: cover;
  border-radius: 50%;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .personas-page {
    padding: 2rem 1rem;
  }

  .personas-grid {
    grid-template-columns: repeat(1, 1fr);
  }

  .persona-card {
    padding: 1.5rem;
  }

  .personas-page h2 {
    font-size: 2rem;
  }
}

/* New styles for expanded card */
.expanded-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 100;
  display: none;
}

.expanded-overlay.active {
  display: flex;
  justify-content: center;
  align-items: center;
}

.expanded-card {
  background: var(--background-color);
  border-radius: 16px;
  padding: 3rem;
  max-width: 800px;
  width: 90%;
  max-height: 90vh;
  overflow-y: auto;
  position: relative;
  box-shadow: 0 20px 40px rgba(0, 0, 0, 0.2);
}

.close-button {
  position: absolute;
  top: 1rem;
  right: 1rem;
  background: none;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
  color: var(--text-color);
  padding: 0.5rem;
  line-height: 1;
  z-index: 1;
}

.close-button:hover {
  color: var(--primary-color);
}

.expanded-card .persona-expanded {
  margin-top: 1rem;
}

/* Hide expanded content in regular card */
.persona-card .persona-expanded {
  display: none;
}